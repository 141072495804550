import React from 'react';
import { graphql } from 'gatsby';
import ArchiveHeader from '../components/ArchiveHeader';
import ProjectList from '../components/ProjectList';
import Seo from '../components/Seo';

const ProjectCategoriesPage = ({ data }) => {
	const { title, seo, project } = data.category || {};
	const projects = project;
	return (
		<>
			<Seo
				metaTitle={seo?.metaTitle || title}
				shareTitle={seo?.shareTitle}
				metaDesc={seo?.metaDesc || title}
				shareDesc={seo?.shareDesc}
			/>
			<ArchiveHeader index={data.index} categories={data.allCategories} />
			<ProjectList projects={projects} />
		</>
	);
};

export default ProjectCategoriesPage;

export const query = graphql`
	query CategoryTemplateQuery($id: String!) {
		index: sanityProjectsIndex {
			id
			title
			slug {
				current
			}
		}
		allCategories: allSanityProjectCategories {
			nodes {
				id
				title
				slug {
					current
				}
			}
		}
		category: sanityProjectCategories(id: { eq: $id }) {
			title
			id
			seo {
				...seoFields
			}
			project {
				...projectFields
			}
		}
	}
`;
